<template>
    <div class="guidng">
        <!-- 手机端 -->
        <div class="head_box phone_head_box">
            <div class="max_cont_width head_cont">
                <router-link class="head_logo" to="/">
                    <img src="../assets/img/csiweb2.png" alt="search" />
                </router-link>
                <!-- 搜索 -->

                <el-badge :class="innotinum > 0 ? 'head_notice' : 'head_notice2'" type="warning" :value="innotinum">
                    <img src="../assets/img/notice_ic.png" @click="gotodabase()" />
                </el-badge>
                <div class="head_login" @click="loginPop" v-if="!loginStatus">
                    <span class="switch_txt">Sign in</span>
                </div>
                <template v-else>
                    <el-popover placement="top" width="230" popper-class="pop_top_account">
                        <div class="head_login" :class="userRole == 'company' ? 'company_role' : 'person_role'" slot="reference">
                            <span class="switch_txt">{{ loginStatus ? 'Sign out' : 'Sign in' }}</span>
                        </div>
                        <div class="head_account">
                            <!-- <div class="top_usage flex_center_between_box"> -->
                            <div class="top_usage">
                                <div class="top_usage-head">
                                    <div class="usage_ic">
                                        <img v-if="userRole == 'company'" src="../assets/img/head_switch_company_ic.png" />
                                        <img v-else src="../assets/img/head_switch_person_ic.png" />
                                    </div>
                                    <span class="ellipsistext" style="font-size: 14px; color: #022955">{{ ucemail }}</span>
                                </div>
                                <div class="usage_prog">
                                    <div class="flex_center_between_box">
                                        <div class="usage_txt">Usage</div>
                                        <div class="usage_total">{{ account.used | FilterNum }} used</div>
                                    </div>
                                    <el-progress v-if="account.total > 0" :show-text="false" :stroke-width="5" define-back-color="#ffffff" :color="customColorMethod" :percentage="parseFloat(((1 - account.used / (account.total + account.used)) * 100).toFixed(2))"></el-progress>
                                    <div class="usage_used" style="display: flex; justify-content: space-between" :class="{ parnterr: percentageerr }">
                                        <div>{{ account.total | FilterNum }}/{{ account.used | FilterNum }}</div>
                                        <div v-if="account.total == 0 && account.used == 0">0</div>
                                        <div v-else>BL: {{ parseFloat((account.total / (account.total + account.used)) * 100).toFixed(2) }}%</div>
                                    </div>
                                </div>
                            </div>
                            <div class="top_acc_menu">
                                <ul class="top_acc_menu_box">
                                    <li v-for="(menu, index) in accountMenuList" :key="index" v-show="menu.name == 'Add Credit' || menu.name == 'My Database' || menu.name == 'FAQ'">
                                        <router-link :to="menu.url" v-if="menu.type != 0" :class="{ parnterr: menu.name == 'Add Credit' && percentageerr, parnterr1: menu.name == 'Add Credit' && !percentageerr }">
                                            <div class="usage_icone usage_ic">
                                                <img :src="menu.img" />
                                            </div>
                                            {{ menu.name }}
                                        </router-link>
                                    </li>
                                    <li class="acc_signout" @click="signOutAccount">
                                        <div class="usage_icone usage_ic">
                                            <img src="../assets/img/user-icons/1.png" />
                                        </div>
                                        Sign out
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </el-popover>
                </template>
                <div class="bhpne_menu phone_page_bg" @click="drawer = true">
                    <img style="height: 33px" src="../assets/img/liebiao2.png" alt="" />
                </div>
                <div class="head_search flex_center" :class="{ search2: seachdoudong }" ref="input">
                    <!-- 选择国家 -->
                    <div class="search_left" :class="countryImg ? 'on' : ''" v-show="headSearchOption != 'Search Products' && headSearchOption != 'Search Sanctions'">
                        <div class="country_flag">
                            <img :class="countryImg ? 'country_flag_img' : 'none'" :src="countryImg" />
                            <!-- <span class="sel_place" v-show="countryName ? false : true">Select Country</span> -->
                            <!-- <span class="sel_value line_2" v-show="countryName ? true : false">{{ countryName }}</span> -->
                        </div>
                        <el-select v-model="countryCode" ref="select" clearable @clear="clearCountry" placeholder="Select Country" popper-class="sel_box" @change="selectCountry" no-match-text="No data" no-data-text="No data">
                            <div class="search_sel_container">
                                <el-tabs tab-position="left" v-model="tabActiveName">
                                    <el-tab-pane v-for="(tab, index) in tabCountryList" :key="index">
                                        <div slot="label" class="flex_center_between_box">
                                            <span class="left_txt">{{ tab.continent.enName }}</span>
                                            <span class="right_txt">{{ tab.continent.countryNumStr }}</span>
                                        </div>
                                        <div class="right">
                                            <el-option v-for="item in tab.countryList" :key="item.id" :value="item.countryCode" :label="item.nameEn" @click.native="labelClick(item.label)">
                                                <div class="right_item flex_box">
                                                    <div class="img flex_center">
                                                        <img :src="item.urlBanner" />
                                                    </div>

                                                    <div class="rBox">
                                                        <div class="coun_title">{{ item.nameEn }}</div>
                                                        <div class="coun_num" v-if="show == 0">{{ item.companyNumStr }}</div>
                                                        <div class="coun_num" v-if="show == 2">{{ item.tradeNumStr }}</div>
                                                        <div class="coun_num" v-if="show == 3">{{ item.peopleNum }}</div>
                                                    </div>
                                                </div>
                                            </el-option>
                                        </div>
                                    </el-tab-pane>
                                </el-tabs>
                            </div>
                        </el-select>
                    </div>

                    <div class="search_left" v-show="headSearchOption == 'Search Sanctions'">
                        <el-select class="search_left_sactions" v-model="sanctionsvalue" slot="prepend" placeholder="Enter company name">
                            <el-option v-for="item in searchoptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </div>
                    <!-- 搜索 -->
                    <div v-show="show == 0" class="search_right flex_center" @click="tab">
                        <input type="text" placeholder="Enter company name" v-model="searchName" class="search_input" @keyup.enter="headSearchParems()" />
                    </div>
                    <div v-show="show == 1" class="search_right flex_center product" @click="tab">
                        <el-autocomplete ref="autocomplete" v-loading="productloding" :highlight-first-item="true" class="search_input" :trigger-on-focus="false" :autofocus="true" v-model="searchProName" clearable :fetch-suggestions="querySearch" placeholder="Enter product name" @select="handleSelect"></el-autocomplete>
                    </div>
                    <div v-show="show == 2" class="search_right flex_center" @click="tab">
                        <input type="text" placeholder="Enter keyword" v-model="searchName" class="search_input" @keyup.enter="headSearchParems()" />
                    </div>
                    <div v-show="show == 3" class="search_right flex_center">
                        <input type="text" placeholder="Enter person name" v-model="searchName" class="search_input" @keyup.enter="headSearchParems()" />
                    </div>
                    <div v-show="show == 4" class="search_right flex_center">
                        <input type="text" placeholder="Enter country name" v-model="searchName" class="search_input" @click="headSearchParems()" @input="headSearchParems()" />
                    </div>
                    <div v-show="show == 5" class="search_right flex_center">
                        <!-- @blur="handleBlur" -->
                        <input type="text" v-model="searchName" placeholder="Enter keyword" class="search_input" @keyup.enter="headSearchParems()" />
                    </div>
                    <div v-show="show == 6" class="search_right flex_center">
                        <!-- @blur="handleBlur" -->
                        <input type="text" v-model="searchName" placeholder="Enter company name" class="search_input" @keyup.enter="headSearchParems()" />
                    </div>
                    <div class="head_swi_keyword" v-show="headSearchOption == 'Search Company'">
                        <el-switch v-model="isKeyword" @change="switchHeadKeyword"></el-switch>
                        <span v-if="isKeyword == true" class="switch_txt">keyword</span>
                        <span v-else class="switch_txt">left partial</span>
                    </div>
                    <div v-if="show == 1" class="search_btn flex_center" @click="productsearch()">
                        <img src="../assets/img/head_search_ic.png" />
                    </div>
                    <div v-else class="search_btn flex_center" @click="headSearchParems()">
                        <img src="../assets/img/head_search_ic.png" />
                    </div>
                </div>
            </div>
        </div>
        <!-- computer -->
        <div class="head_box computer_head_box">
            <div class="max_cont_width head_cont">
                <router-link class="head_logo" to="/">
                    <img src="../assets/img/logo_white.png" alt="search" />
                </router-link>
                <!-- 搜索 -->
                <div class="head_search flex_center" :class="{ search2: seachdoudong }" ref="input">
                    <!-- 选择国家 -->
                    <div class="search_left" :class="countryImg ? 'on' : ''" v-show="headSearchOption != 'Search Products' && headSearchOption != 'Search Sanctions'">
                        <div class="country_flag">
                            <img :class="countryImg ? 'country_flag_img' : 'none'" :src="countryImg" />
                            <!-- <span class="sel_place" v-show="countryName ? false : true">Select Country</span> -->
                            <!-- <span class="sel_value line_2" v-show="countryName ? true : false">{{ countryName }}</span> -->
                        </div>
                        <el-select v-model="countryCode" ref="select" clearable @clear="clearCountry" placeholder="Select Country" popper-class="sel_box" @change="selectCountry" no-match-text="No data" no-data-text="No data">
                            <div class="search_sel_container">
                                <el-tabs tab-position="left" v-model="tabActiveName">
                                    <el-tab-pane v-for="(tab, index) in tabCountryList" :key="index">
                                        <div slot="label" class="flex_center_between_box">
                                            <span class="left_txt">{{ tab.continent.enName }}</span>
                                            <span class="right_txt">{{ tab.continent.countryNumStr }}</span>
                                        </div>
                                        <div class="right">
                                            <el-option v-for="item in tab.countryList" :key="item.id" :value="item.countryCode" :label="item.nameEn" @click.native="labelClick(item.label)">
                                                <div class="right_item flex_box">
                                                    <div class="img flex_center">
                                                        <img :src="item.urlBanner" />
                                                    </div>

                                                    <div class="rBox">
                                                        <div class="coun_title">{{ item.nameEn }}</div>
                                                        <div class="coun_num" v-if="show == 0">{{ item.companyNumStr }}</div>
                                                        <div class="coun_num" v-if="show == 2">{{ item.tradeNumStr }}</div>
                                                        <div class="coun_num" v-if="show == 3">{{ item.peopleNum }}</div>
                                                    </div>
                                                </div>
                                            </el-option>
                                        </div>
                                    </el-tab-pane>
                                </el-tabs>
                            </div>
                        </el-select>
                    </div>
                    <!-- 选择产品 -->
                    <div class="search_left" v-show="headSearchOption == 'Search Products'">
                        <el-cascader ref="cascaderProducts" class="head_pro_cas" placeholder="Select Products" collapse-tags v-model="productChose" :options="productOptions" :props="optionsProps" :show-all-levels="false" popper-class="head_cas" @change="handleChange">
                            <template slot-scope="{ data }">
                                <el-tooltip popper-class="totip" effect="dark" placement="top" :content="data.nameEn">
                                    <span>{{ data.nameEn }}</span>
                                </el-tooltip>

                                <!-- <span>{{ data }}</span> -->
                            </template>
                        </el-cascader>
                    </div>
                    <div class="search_left" v-show="headSearchOption == 'Search Sanctions'">
                        <el-select class="search_left_sactions" v-model="sanctionsvalue" slot="prepend" placeholder="Enter company name">
                            <el-option v-for="item in searchoptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </div>
                    <!-- 搜索 -->
                    <div v-show="show == 0" class="search_right flex_center" @click="tab">
                        <input type="text" placeholder="Enter company name" v-model="searchName" class="search_input" @keyup.enter="headSearchParems()" />
                    </div>
                    <div v-show="show == 1" class="search_right flex_center product" @click="tab">
                        <el-autocomplete ref="autocomplete" v-loading="productloding" :highlight-first-item="true" class="search_input" :trigger-on-focus="false" :autofocus="true" v-model="searchProName" :fetch-suggestions="querySearch" placeholder="Enter product name" @select="handleSelect"></el-autocomplete>
                    </div>
                    <div v-show="show == 2" class="search_right flex_center" @click="tab">
                        <input type="text" placeholder="Enter keyword" v-model="searchName" class="search_input" @keyup.enter="headSearchParems()" />
                    </div>
                    <div v-show="show == 3" class="search_right flex_center">
                        <input type="text" placeholder="Enter person name" v-model="searchName" class="search_input" @keyup.enter="headSearchParems()" />
                    </div>
                    <div v-show="show == 4" class="search_right flex_center">
                        <input type="text" placeholder="Enter country name" v-model="searchName" class="search_input" @click="headSearchParems()" @input="headSearchParems()" />
                    </div>
                    <div v-show="show == 5" class="search_right flex_center">
                        <!-- @blur="handleBlur" -->
                        <input type="text" v-model="searchName" placeholder="Enter keyword" class="search_input" @keyup.enter="headSearchParems()" />
                    </div>
                    <div v-show="show == 6" class="search_right flex_center">
                        <!-- @blur="handleBlur" -->
                        <input type="text" v-model="searchName" placeholder="Enter company name" class="search_input" @keyup.enter="headSearchParems()" />
                    </div>
                    <div class="head_swi_keyword" v-show="headSearchOption == 'Search Company'">
                        <el-switch v-model="isKeyword" @change="switchHeadKeyword"></el-switch>
                        <span v-if="isKeyword == true" class="switch_txt">keyword</span>
                        <span v-else class="switch_txt">left partial</span>
                    </div>
                    <div v-if="show == 1" class="search_btn flex_center" @click="productsearch()">
                        <img src="../assets/img/head_search_ic.png" />
                    </div>
                    <div v-else class="search_btn flex_center" @click="headSearchParems()">
                        <img src="../assets/img/head_search_ic.png" />
                    </div>
                </div>

                <el-badge :class="innotinum > 0 ? 'head_notice' : 'head_notice2'" type="warning" :value="innotinum">
                    <img src="../assets/img/notice_ic.png" @click="gotodabase()" />
                </el-badge>
                <div class="head_login" @click="loginPop" v-if="!loginStatus">
                    <span class="switch_txt">Sign in</span>
                </div>
                <template v-else>
                    <el-popover placement="top" width="230" popper-class="pop_top_account">
                        <!-- my Account -->

                        <div class="head_login" :class="userRole == 'company' ? 'company_role' : 'person_role'" slot="reference">
                            <span class="switch_txt">{{ loginStatus ? 'Sign out' : 'Sign in' }}</span>
                        </div>
                        <div class="head_account">
                            <!-- <div class="top_usage flex_center_between_box"> -->
                            <div class="top_usage">
                                <div class="top_usage-head">
                                    <div class="usage_ic">
                                        <img v-if="userRole == 'company'" src="../assets/img/head_switch_company_ic.png" />
                                        <img v-else src="../assets/img/head_switch_person_ic.png" />
                                    </div>
                                    <span class="ellipsistext" style="font-size: 14px; color: #022955">{{ ucemail }}</span>
                                </div>
                                <div class="usage_prog">
                                    <div class="flex_center_between_box">
                                        <div class="usage_txt">Usage</div>
                                        <div class="usage_total">{{ account.used | FilterNum }} used</div>
                                    </div>
                                    <el-progress v-if="account.total > 0" :show-text="false" :stroke-width="5" define-back-color="#ffffff" :color="customColorMethod" :percentage="parseFloat(((1 - account.used / (account.total + account.used)) * 100).toFixed(2))"></el-progress>
                                    <div class="usage_used" style="display: flex; justify-content: space-between" :class="{ parnterr: percentageerr }">
                                        <div>{{ account.total | FilterNum }}/{{ account.used | FilterNum }}</div>
                                        <div v-if="account.total == 0 && account.used == 0">0</div>
                                        <div v-else>BL: {{ parseFloat((account.total / (account.total + account.used)) * 100).toFixed(2) }}%</div>
                                    </div>
                                </div>
                            </div>
                            <div class="top_acc_menu">
                                <ul class="top_acc_menu_box">
                                    <li v-for="(menu, index) in accountMenuList" :key="index">
                                        <router-link :to="menu.url" v-if="menu.type != 0" :class="{ parnterr: menu.name == 'Add Credit' && percentageerr, parnterr1: menu.name == 'Add Credit' && !percentageerr }">
                                            <div class="usage_icone usage_ic">
                                                <img :src="menu.img" />
                                            </div>
                                            {{ menu.name }}
                                        </router-link>
                                    </li>
                                    <li class="acc_signout" @click="signOutAccount">
                                        <div class="usage_icone usage_ic">
                                            <img src="../assets/img/user-icons/1.png" />
                                        </div>
                                        Sign out
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </el-popover>
                </template>
            </div>
        </div>
        <div class="head_menu_box computer_head_box">
            <div class="max_cont_width phone_max_cont_width">
                <el-radio-group v-model="headSearchOption" class="head_menu_list" @change="tab(headSearchOption)">
                    <el-radio-button :label="item.name" v-for="(item, index) in menuList" :key="index" class="head_menu_item" @click="tab(item.name)"></el-radio-button>
                </el-radio-group>
            </div>
        </div>
        <div class="websorct" v-if="messages.length > 0">
            <ul>
                <div class="websorct-titel">
                    <div>Message center ({{ messages.length }} unread items)</div>
                    <el-button class="btn_export" @click="websockmess" :disabled="messages.length > 0 ? false : true">All read</el-button>
                </div>
                <li v-for="(message, index) in messages" class="websorctli" :key="index">
                    <div class="websorctlitext">
                        <a style="display: inline-flex; width: 70px; margin-right: 10px">Title:</a>
                        {{ message.title }}
                    </div>
                    <div class="websorctlitext">
                        <a style="width: 70px; width: 70px; margin-right: 10px">Company:</a>
                        "{{ message.content }}"
                    </div>
                </li>
            </ul>
        </div>
        <Login :loginAndCreatePop.sync="loginDialog" @getLoginStatus="getLoginStatus"></Login>
        <!-- 手机端导航栏 -->
        <el-drawer title="" :visible.sync="drawer" size="350px" :with-header="false" direction="ttb" :show-close="true" :wrapperClosable="true" :append-to-body="true">
            <transition name="fade" :duration="400">
                <div class="phone_page_bg sna_box">
                    <div class="phone_left_menu">
                        <div class="max_cont_width phone_max_cont_width">
                            <el-radio-group v-model="headSearchOption" class="head_menu_list" @change="tab(headSearchOption)">
                                <el-radio-button :label="item.name" v-for="(item, index) in menuList" :key="index" class="head_menu_item" @click="tab(item.name)"></el-radio-button>
                            </el-radio-group>
                        </div>
                    </div>
                </div>
            </transition>
        </el-drawer>
        <Phonetocsi v-if="window.innerWidth < 821" :suoClose="moreclose" @closeCloseDialog="closmoreDialog($event)"></Phonetocsi>
    </div>
</template>
<script>
import { getProductTreeList, selectProductTypeCode, getofflineDatacount } from '@/api/searchApi.js';
import { getCountryCollection } from '@/api/companyApi.js';
import { LoginOut } from '@/api/api.js';
import Login from '@/components/login';
import { mapGetters } from 'vuex';
import bus from './bus';
import { mixins1 } from '@/mixins/index';
import { createWebSocketConnection } from '@/api/userApi';
import Phonetocsi from '@/components/phonetocsi.vue';
import { mapActions } from 'vuex';
export default {
    mixins: [mixins1],
    components: { Login, Phonetocsi },
    props: {
        upadteQuotaStatus: {
            type: Boolean,
        },
    },
    data() {
        return {
            code: '',
            timeserve: null,
            headSearchOption: 'Search Company',
            account: { total: '', used: '' },
            loginDialog: false,
            isKeyword: false, //头部搜索框keyword
            keword: 1,
            show: 0,
            seachdoudong: false,
            //国家分类
            tabCountryList: [],
            productloding: false,
            percentageerr: false,
            countryCode1: '',
            countryCode: '',
            countryName: '', //选中国家
            countryImg: '', //选中国家的国旗
            tabActiveName: '', //选择的区域
            isNotice: true, //通知高亮
            innotinum: 0,
            loginStatus: false, //登录状态
            userRole: 'company', //用户角色
            ucemail: JSON.parse(localStorage.getItem('userInfo'))?.userInfo.loginName,
            loginToken: localStorage.getItem('token'),

            //菜单
            menuList: [
                {
                    name: 'Search Company',
                    // path: '/search/searchCompany',
                },
                {
                    name: 'Search Products',
                    // path: '/search/searchProduct',
                },
                {
                    name: 'Search Trade',
                    // path: '/search/searchTrade',
                },
                // {
                //     name: 'Search Person',
                //     // path: '/search/searchPerson',
                // },
                {
                    name: 'Search Country',
                    // path: '/country',
                },
                {
                    name: 'Search News',
                    // path: '#',
                },
                {
                    name: 'Search Sanctions',
                    // path: '#',
                },
            ],
            //account 菜单
            accountMenuList: [],
            searchName: '',
            searchProName: '',
            productOptions: [], //this.$store.state.productOptions.length>0?this.$store.state.productOptions:this.handleRadioChange(),
            optionsProps: {
                label: 'nameEn', //名称展示
                value: 'displayCode', //值展示
                // checkStrictly: true, //是否严格的遵守父子节点不互相关联
                expandTrigger: 'click', //次级菜单的展开方式
                emitPath: false,
            },
            productChose: '',
            menuInfos: [],
            searchoptions: [
                {
                    value: 'Entity',
                    label: 'Company',
                },
                {
                    value: 'Person',
                    label: 'Person',
                },
            ],
            sanctionsvalue: '',
            usercode: JSON.parse(localStorage.getItem('userInfo'))?.userInfo.userCode,
            // socket: null, // 存储WebSocket实例
            websocketConnected: false, // 标志位，表示WebSocket是否已连接
            messages: [],
            webmseesshow: false,
            drawer: false,
            moreclose: false,
            window: window,
            pv: 0,
            uv: 0,
            bounceRate: 0,
        };
    },
    computed: {
        ...mapGetters({
            token: 'users/token',
        }),
        // ...mapState('websocket', ['socket']),
        socketState() {
            return this.$store.state.websocket.socket;
        },
        messagesstate() {
            return this.$store.state.websocket.messages;
        },
    },
    watch: {
        // 处理游客路由切换登录谈筐不出现
        $route: {
            handler(newVal, oldVal) {
                if (this.$route.name == 'SearchPerson' || newVal.name == 'Roles' || newVal.name == 'Background') {
                    this.show = 3;
                }
                if (this.$route.query.information == 'Search Products') {
                    this.show = 1;
                }
                if (this.$route.name == 'CountryData') {
                    this.show = 4;
                }
                bus.$on('headLogFlg', headFlg => {
                    this.loginDialog = true; //是否显示登录
                });
            },
            immediate: true,
        },
        socketState: {
            handler(newValue, oldValue) {
                // 将socketState的值赋给data中的socket属性
                if (newValue == null) {
                    if (this.loginToken != null) {
                        this.connectWebSocket(this.usercode);
                    }
                }
                this.messages = this.$store.state.websocket.messages;

                // 处理新值和旧值的变化

                // 执行其他的操作
            },
            immediate: true, // 立即触发一次handler函数，,
            deep: true,
        },
        messagesstate: {
            handler(newValue, oldValue) {
                // 将socketState的值赋给data中的socket属性

                this.messages = this.$store.state.websocket.messages;

                // 处理新值和旧值的变化

                // 执行其他的操作
            },
            immediate: true, // 立即触发一次handler函数，,
            deep: true,
        },
        loginToken: {
            handler(newVal, oldVal) {
                if (newVal) {
                    this.getofflineData();

                    // this.openMenu = ['2', '3', '4', '5', '6', '7', '8'];
                }
            },
            immediate: true,
        },

        upadteQuotaStatus(newVal, oldVal) {
            if (newVal) {
                this.getAccountMsg();
            }
        },
    },
    updated() {
        window.addEventListener('setItem', () => {
            this.loginToken = localStorage.getItem('token');
        });
    },
    destroyed() {
        bus.$off('headLogFlg');
        window.removeEventListener('setItem', () => {
            this.loginToken = localStorage.getItem('token');
        });
        window.removeEventListener('beforeunload', this.handlePageRefresh);
    },
    mounted() {
        this.route();

        if (this.$store.state?.productOptions?.length > 0) {
            // let productChose = (this.productChose = this.$route.query.searchname ? this.$route.query.searchname : '');
            this.productOptions = this.$store.state.productOptions;
        } else {
            this.handleRadioChange();
        }
        const selproduct = this.$route.query.selproduct;

        this.productChose = selproduct ? selproduct : '';
        //account
        if (this.loginStatus) {
            this.getAccountMsg();
        }
        this.padaun();

        if (this.loginToken) {
            if (JSON.parse(sessionStorage.getItem('socket')) == null) {
                // this.createWebSocketConnectionmtd();
            }
        }
        window.addEventListener('beforeunload', this.handlePageRefresh);
    },
    created() {
        this.loginToken ? (this.loginStatus = true) : (this.loginStatus = false);
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        // this.getConditions();
        //登陆状态
        // bus.$on('handleLogin', loginCheck => {
        // this.loginStatus = loginCheck;
        // const user = JSON.parse(localStorage.getItem('userInfo'));
        // this.userRole = user.roleInfos[0].sysRoleInfo.name == '企业' || user.roleInfos[0].sysRoleInfo.name == '集团' ? 'company' : 'person';
        // });
        bus.$on('headLogFlg', headFlg => {
            this.loginDialog = true; //是否显示登录
        });
        this.getAllCountry();
        this.getTabSelect();

        this.customColorMethod();
        //   this.updateStatistics();
    },

    methods: {
    //      updateStatistics() {
    //   // 更新浏览量（PV）
    //   this.pv = localStorage.getItem('pv') ? parseInt(localStorage.getItem('pv')) + 1 : 1;
    //   localStorage.setItem('pv', this.pv);

    //   // 更新访客数（UV）
    //   if (!localStorage.getItem('uv')) {
    //     this.uv = 1;
    //     localStorage.setItem('uv', 1);
    //   } else {
    //     this.uv = parseInt(localStorage.getItem('uv'));
    //   }

    //   // 计算跳出率
    //   this.bounceRate = (this.pv - this.uv) / this.pv * 100;
    // },
    // saveStatistics() {
    //   // 发送统计数据到后端
    //   axios.post('http://your-api-url/saveStatistics', {
    //     pv: this.pv,
    //     uv: this.uv,
    //     bounceRate: this.bounceRate
    //   })
    //   .then(response => {
    //     console.log('Statistics saved to backend');
    //   })
    //   .catch(error => {
    //     console.error('Error saving statistics:', error);
    //   });
    // },
 
        closmoreDialog(value) {
            this.moreclose = value;
        },
        handleResize() {},
        ...mapActions({
            connectWebSocket: 'websocket/connectWebSocket',
        }),
        handlePageRefresh() {
            sessionStorage.removeItem('socket');

            // 在这里执行页面刷新时的操作
        },

        deleteMessages(index) {
            // 检查索引是否超出数组长度
            if (index >= this.messages.length) {
                return;
            }

            // 延迟三秒执行删除操作
            setTimeout(() => {
                // 删除指定索引位置的消息
                this.messages.splice(index, 1);

                // 递归调用，处理下一个索引位置的消息
                this.deleteMessages(index);
            }, 1000);
        },
        // 鼠标移入显示消息
        websockmess() {
            if (this.messages.length > 0) {
                this.deleteMessages(0);
            }
        },
        getofflineData() {
            getofflineDatacount({ userCode: this.usercode }).then(res => {
                // if(res.data>0){
                this.innotinum = res.data;
            });
            let that = this;
            that.timeserve = setInterval(() => {
                that.loginToken = localStorage.getItem('token');
                if (this.loginToken == null) {
                    clearInterval(that.timeserve);
                    that.timeserve = null;
                }
                if (that.loginToken) {
                    getofflineDatacount({ userCode: this.usercode }).then(res => {
                        // if(res.data>0){
                        that.innotinum = res.data;
                    });
                }
            }, 10 * 1000);
        },
        // 点击铃铛
        gotodabase() {
            // let userinfo = JSON.parse(sessionStorage.getItem('userInfo')).userInfo;

            let routeData = this.$router.resolve({
                path: '/account/myDatabase',
            });
            localStorage.setItem('show', 'Updated');
            this.$EventBus.$emit('showbell', 'Updated');
            window.open(routeData.href, '_blank');
        },

        handleBlur() {
            if (this.searchName == '') {
                this.seachdoudong = true;
                this.$refs.input.classList.add('shake');
                setTimeout(() => {
                    this.$refs.input.classList.remove('shake');
                }, 500);
            }
        },
        padaun() {
            const user = JSON.parse(localStorage.getItem('userInfo'));
            this.ucemail = user.userInfo.loginName;
            this.userRole = user.roleInfos[0].sysRoleInfo.name == '企业' || user.roleInfos[0].sysRoleInfo.name == '集团' ? 'company' : 'person';
            if (this.account.total == 0) {
                this.percentageerr = true;
                this.$emit('parterr', this.percentageerr);
            }
        },
        // 个人积分比例判断
        customColorMethod(percentage) {
            if (percentage < 5 || this.account.total == 0) {
                // this.$emit()

                this.percentageerr = true;
                this.$emit('parterr', this.percentageerr);

                return '#f56c6c';
            } else if (percentage < 10) {
                this.percentageerr = false;
                this.$emit('parterr', this.percentageerr);
                return '#e6a23c';
            } else {
                this.percentageerr = false;
                this.$emit('parterr', this.percentageerr);
                return '#1290c9';
            }
        },
        route() {
            if (this.$route.name == 'SearchProduct') {
                this.show = 1;
            }
            if (this.$route.path == '') console.log(this.$route);
        },
        // 下拉选择国家
        handleSelect(item) {
            this.code = item.display_code;

            this.headSearchParems();
        },
        // /   处理产品点击搜索无参数问题
        productsearch() {
            if (this.$refs.autocomplete.suggestions[0] == undefined || this.searchProName == '') {
                this.headSearchParems();
            } else {
                this.searchProName = this.$refs.autocomplete.suggestions[0].value;
                this.code = this.$refs.autocomplete.suggestions[0].display_code;

                this.headSearchParems();
            }
        },

        querySearch(queryString, cb) {
            this.productloding = true;
            if (queryString !== '') {
                const param = {
                    keyword: this.searchProName,
                    pageNum: 1,
                    pageSize: 369,
                    // productKeyword: this.$route.query.searchcode,
                    // productThirdKeyword: queryString,
                    // company_country: 'CN@@US',
                };
                selectProductTypeCode(param)
                    .then(res => {
                        const data = res.data;
                        if (res.code) {
                            this.productloding = false;
                            data.rows.forEach(item => {
                                item.val = item.display_code;
                                item.value = item.en;
                                if (this.searchProName != item.en) {
                                    this.code = '';
                                }
                            });
                            if (data.rows == []) {
                                this.code = '';
                            }
                            cb(data.rows);
                        } else {
                            this.productloding = false;
                            this.code = '';
                            cb([]);
                        }
                    })
                    .catch(() => {
                        this.productloding = false;
                        this.code = '';
                        cb([]);
                    });
            }
        },
        //登陆状态
        getLoginStatus(val) {
            if (val) {
                this.loginStatus = val;
                const user = JSON.parse(localStorage.getItem('userInfo'));
                this.ucemail = user.userInfo.loginName;
                this.userRole = user.roleInfos[0].sysRoleInfo.name == '企业' || user.roleInfos[0].sysRoleInfo.name == '集团' ? 'company' : 'person';

                //account
                this.getAccountMsg();
                // this.getofflineData()
            }
        },
        tab(page) {
            this.tabCountryList = JSON.parse(localStorage.getItem('countryList'));

            if (JSON.parse(localStorage.getItem('countryList'))) {
                let list = this.tabCountryList;
                list.forEach((item, index) => {
                    item.countryList.forEach(it => {
                        // let countryImg = this.getCountryImgUrl(it.countryCode)
                        let countryImg = '';
                        // /imagev2/zanwutupian.png
                        if (it.urlBanner == null) {
                            countryImg = '/imagev2/zanwutupian.png';
                        } else {
                            countryImg = it.urlBanner.split('/static')[1];
                        }
                        // let flag = require('../../static' + countryImg);
                          let flag = '';
                        try {
                            flag = require('../../static' + countryImg);
                        } catch (error) {
                            countryImg = '/imagev2/zanwutupian.png';
                            // console.error('Error loading country image:', error);
                            // 提供一个默认值或者其他处理方式
                            flag = require('../../static' + countryImg);
                        }
                        it.urlBanner = flag;
                    });
                });
            }
            this.seachdoudong = false;
            if (page == 'Search Company') {
                this.show = 0;
                this.searchName = '';
            }
            if (page == 'Search Products') {
                this.show = 1;
                this.headSearchOption = 'Search Products';
            }
            if (page == 'Search Trade') {
                this.searchName = '';
                this.show = 2;
            }
            if (page == 'Search Person') {
                this.searchName = '';
                this.show = 3;
            }
            if (page == 'Search Country') {
                this.searchName = '';
                this.show = 4;
            }
            if (page == 'Search News') {
                this.searchName = '';
                this.show = 5;
            }
            if (page == 'Search Sanctions') {
                this.searchName = '';
                this.show = 6;
            }

            if (this.window.innerWidth < 821) {
                if (this.headSearchOption == 'Search Trade' || this.headSearchOption == 'Search Sanctions') {
                    this.headSearchOption = 'Search Company';
                    this.show = 0;
                    this.moreclose = true;
                }
            }
            // 匹配道琼斯国家code
            let newscountry = this.tabCountryList[0].countryList;
            newscountry.map(item => {
                if (item.countryCode == this.$route.query.companyCountry) {
                    this.countryCode1 = item.dowjonesCountrycode;
                }
            });
            // this.headSearchParems()
        },

        //切换keyword
        switchHeadKeyword() {
            if (this.isKeyword == true) {
                this.keword = 2;
            } else {
                this.keword = 1;
            }
        },
        //头部搜索
        headSearchParems() {
            let page = this.headSearchOption;

            if (page == 'Search Company') {
                if (this.searchName) {
                    this.show = 0;
                    this.seachdoudong = false;
                    this.$store.commit('setRefreshKey', new Date().getTime());
                    this.$router.push({ path: '/search/searchCompany', query: { searchname: this.searchName, companyCountry: this.countryCode, keword: this.keword, companyCountrydqs: this.countryCode1 } });
                } else {
                    this.$message({
                        message: 'Enter company name',
                        center: true,
                        type: 'error',
                    });
                }
                this.handleBlur();
            } else if (page == 'Search Products') {
                this.handleBlur();
                if (this.searchProName) {
                    this.productChose = '';
                }
                let labName = this.$refs['cascaderProducts'].getCheckedNodes()[0] ? this.$refs['cascaderProducts'].getCheckedNodes()[0].value : '';
                // 产品下拉选择lable
                let labNameselect = this.$refs['cascaderProducts'].getCheckedNodes()[0] ? this.$refs['cascaderProducts'].getCheckedNodes()[0].label : '';

                if (this.searchProName) {
                    this.show = 1;
                    this.$store.commit('setRefreshKey', new Date().getTime());
                    labName = '';
                    this.headSearchOption = 'Search Products';
                    this.seachdoudong = false;
                    this.$router.push({ path: '/search/searchProduct', query: { searchname: this.code, searchcode: labName, seach: this.searchProName, companyCountrydqs: this.countryCode1, selectlable: labNameselect } });
                } else if (this.productChose) {
                    this.show = 1;
                    this.$store.commit('setRefreshKey', new Date().getTime());
                    this.headSearchOption = 'Search Products';
                    this.seachdoudong = false;
                    this.$router.push({ path: '/search/searchProduct', query: { searchname: this.code, searchcode: labName, seach: this.searchProName, companyCountrydqs: this.countryCode1, selectlable: labNameselect } });
                } else {
                    this.code = '';
                    this.$message({
                        message: 'Please Select Products',
                        center: true,
                        type: 'error',
                    });
                }
                // this.$router.push({ path: '/search/searchProduct', query: { searchname: this.code, searchcode: labName, seach: this.searchProName } });
            } else if (page == 'Search Trade') {
                this.handleBlur();
                if (this.searchName) {
                    this.show = 2;
                    this.$store.commit('setRefreshKey', new Date().getTime());
                    this.headSearchOption = 'Search Trade';
                    this.seachdoudong = false;
                    this.$router.push({ path: '/search/searchTrade', query: { searchname: this.searchName, companyCountry: this.countryCode, companyCountrydqs: this.countryCode1 } });
                } else {
                    this.$message({
                        message: 'Enter keyword',
                        center: true,
                        type: 'error',
                    });
                }
            } else if (page == 'Search Person') {
                this.handleBlur();
                if (this.searchName) {
                    this.show = 3;
                    this.$store.commit('setRefreshKey', new Date().getTime());
                    this.headSearchOption = 'Search Person';
                    this.seachdoudong = false;
                    this.$router.push({ path: '/search/searchPerson', query: { searchname: this.searchName, companyCountry: this.countryCode, companyCountrydqs: this.countryCode1 } });
                } else {
                    this.$message({
                        message: 'Enter person name',
                        center: true,
                        type: 'error',
                    });
                }
            } else if (page == 'Search Country') {
                this.filterMethod(this.searchName, this.tabActiveName);
                // if (this.countryCode) {
                //     this.show = 4;
                //     this.$emit('setCouRefreshKey', new Date().getTime());
                //     this.headSearchOption = 'Search Country';

                //     this.seachdoudong = false;

                //     this.$router.push({ path: '/country', query: { companyCountry: this.countryCode, companyCountrydqs: this.countryCode1 } });
                // } else {
                //     this.$message({
                //         message: 'Please Select Country',
                //         center: true,
                //         type: 'error',
                //     });
                // }
            } else if (page == 'Search News') {
                this.handleBlur();
                if (this.searchName) {
                    this.show = 5;
                    this.$store.commit('setRefreshKey', new Date().getTime());
                    this.headSearchOption = 'Search News';
                    this.seachdoudong = false;
                    this.$router.push({ path: '/search/searchNews', query: { searchname: this.searchName, companyCountry: this.countryCode, companyCountrydqs: this.countryCode1 } });
                } else {
                    this.$message({
                        message: 'Enter keyword',
                        center: true,
                        type: 'error',
                    });
                }
            } else if (page == 'Search Sanctions') {
                this.handleBlur();
                if (this.searchName) {
                    this.show = 6;
                    this.$store.commit('setRefreshKey', new Date().getTime());
                    this.headSearchOption = 'Search Sanctions';
                    this.seachdoudong = false;
                    this.$router.push({ path: '/search/searchSanctions', query: { searchname: this.searchName, companyCountry: this.countryCode, companyCountrydqs: this.countryCode1, type: this.sanctionsvalue } });
                } else {
                    this.$message({
                        message: 'Enter keyword',
                        center: true,
                        type: 'error',
                    });
                }
            }
            // this.getTabSelect();
        },
        filterMethod(query, index) {
            const select = this.$refs.mySelect;
            // 处理缓存问题
            let tabCountryList = JSON.parse(localStorage.getItem('countryList'));

            if (JSON.parse(localStorage.getItem('countryList'))) {
                let list = tabCountryList;
                list.forEach((item, index) => {
                    item.countryList.forEach(it => {
                        // let countryImg = this.getCountryImgUrl(it.countryCode)
                        let countryImg = '';
                        // /imagev2/zanwutupian.png
                        if (it.urlBanner == null) {
                            countryImg = '/imagev2/zanwutupian.png';
                        } else {
                            countryImg = it.urlBanner.split('/static')[1];
                        }
                        // let flag = require('../../static' + countryImg);
                          let flag = '';
                        try {
                            flag = require('../../static' + countryImg);
                        } catch (error) {
                            countryImg = '/imagev2/zanwutupian.png';
                            // console.error('Error loading country image:', error);
                            // 提供一个默认值或者其他处理方式
                            flag = require('../../static' + countryImg);
                        }
                        it.urlBanner = flag;
                        // if (countryCode && it.countryCode == countryCode) {
                        //     this.countryName = it.nameEn;
                        //     this.countryImg = it.urlBanner;
                        // }
                    });
                });
            }

            this.tabCountryList[index].countryList = tabCountryList[index].countryList.filter(item => {
                return item.nameEn.toLowerCase().indexOf(query.toLowerCase()) > -1;
            });

            let that = this;

            this.$nextTick(() => {
                that.$refs.select.visible = true;
                // console.log(`  that.$refs.select`,  that.$refs.select );
            });
        },
        //选中tab
        getTabSelect() {
            const str = this.$route.path;
            let index = str.lastIndexOf('/');
            let result = str.substr(index + 1, str.length);
            let information = this.$route.query.information;
            if (result == 'searchProduct' || information == 'Search Products') {
                this.headSearchOption = 'Search Products';
            } else if (result == 'searchTrade' || information == 'Search Trade') {
                this.headSearchOption = 'Search Trade';
            } else if (result == 'searchPerson' || information == 'Search Person') {
                this.headSearchOption = 'Search Person';
            } else if (result == 'country' && this.countryCode) {
                this.headSearchOption = 'Search Country';
            } else if (result == 'searchNews') {
                this.headSearchOption = 'Search News';
            } else if (result == 'searchSanctions' || this.$route.name == 'searchSanctions' || this.$route.name == 'Newsanction' || information == 'Search Sanctions') {
                this.headSearchOption = 'Search Sanctions';
            } else {
                this.headSearchOption = 'Search Company';
            }
        },
        //获取products 菜单
        async handleRadioChange() {
            let res = await getProductTreeList('deep=4');
            if (res && res.code) {
                this.productOptions = res.data;
                this.$store.state.productOptions = res.data;
            }
        },
        //获取国家
        async getAllCountry() {
            let countryCode = (this.countryCode = this.$route.query.companyCountry ? this.$route.query.companyCountry : '');
            // localStorage.setItem('countryList',JSON.stringify(countryImgList))
            // 处理缓存问题
            if (JSON.parse(localStorage.getItem('countryList'))) {
                this.tabCountryList = JSON.parse(localStorage.getItem('countryList'));
                let list = this.tabCountryList;
                list.forEach((item, index) => {
                    item.countryList.forEach(it => {
                        let countryImg = '';
                        if (it.urlBanner == null) {
                            countryImg = '/imagev2/zanwutupian.png';
                        } else {
                            countryImg = it.urlBanner.split('/static')[1];
                        }
                        // let flag = require('../../static' + countryImg);
                            let flag=''
                     try {
                            flag = require('../../static' + countryImg);
                        } catch (error) {
                               countryImg = '/imagev2/zanwutupian.png';
                            // console.error('Error loading country image:', error);
                            // 提供一个默认值或者其他处理方式
                              flag = require('../../static' + countryImg);
                        }
                        it.urlBanner = flag;
                        if (countryCode && it.countryCode == countryCode) {
                            this.countryName = it.nameEn;
                            this.countryImg = it.urlBanner;
                        }
                    });
                });
            }

            // 清理缓存后重新请求页面
            let res = await getCountryCollection();
            if (res && res.code) {
                this.$store.state.countryImgList = JSON.parse(localStorage.getItem('countryList')); //res.data.rows
                this.tabCountryList = JSON.parse(localStorage.getItem('countryList')) == null ? res.data.rows : JSON.parse(localStorage.getItem('countryList'));

                let list = this.tabCountryList;

                list.forEach((item, index) => {
                    item.countryList.forEach(it => {
                        let countryImg = '';
                        // /imagev2/zanwutupian.png
                        if (it.urlBanner == null) {
                            countryImg = '/imagev2/zanwutupian.png';
                        } else {
                            countryImg = it.urlBanner.split('/static')[1];
                        }
                        // let flag = require('../../static' + countryImg);
                          let flag = '';
                        try {
                            flag = require('../../static' + countryImg);
                        } catch (error) {
                            countryImg = '/imagev2/zanwutupian.png';
                            // console.error('Error loading country image:', error);
                            // 提供一个默认值或者其他处理方式
                            flag = require('../../static' + countryImg);
                        }
                        it.urlBanner = flag;
                        if (countryCode && it.countryCode == countryCode) {
                            this.countryName = it.nameEn;
                            this.countryImg = it.urlBanner;
                        }
                    });
                });
            }
        },
        handleChange() {
            this.searchProName = '';
            let labName = this.$refs['cascaderProducts'].getCheckedNodes()[0] ? this.$refs['cascaderProducts'].getCheckedNodes()[0].pathLabels : '';
            this.headSearchParems();
        },
        //下拉选择国家
        selectCountry(val) {
            let countryImg = this.getCountryImgUrl(val);
            let flag = require('../../static' + countryImg);
            this.countryImg = flag;
            this.countryCode = val;

            if (this.headSearchOption == 'Search Company') {
                // this.headSearchParems();
            }
            let newscountry = this.tabCountryList[0].countryList;
            newscountry.map(item => {
                if (item.countryCode == val) {
                    this.countryCode1 = item.dowjonesCountrycode;
                }
            });
        },
        labelClick(label) {
            this.countryName = label;
            if (this.headSearchOption == 'Search Country') {
                if (this.countryCode) {
                    this.show = 4;
                    this.$emit('setCouRefreshKey', new Date().getTime());
                    this.headSearchOption = 'Search Country';

                    this.seachdoudong = false;

                    this.$router.push({ path: '/country', query: { companyCountry: this.countryCode, companyCountrydqs: this.countryCode1 } });
                } else {
                    this.$message({
                        message: 'Please Select Country',
                        center: true,
                        type: 'error',
                    });
                }
            }
        },
        //清除选择的国家
        clearCountry() {
            this.countryImg = '';
            this.countryCode = '';
            this.countryCode1 = '';
        },
        //去登录
        loginPop() {
            if (this.loginStatus && this.this.loginToken) {
                this.loginDialog = false;
                this.loginStatus = true;
                this.getAccountMsg();
            } else {
                this.loginDialog = true;
                this.loginStatus = false;
            }
        },

        //Sign out 登出
        signOutAccount() {
            const userCode = JSON.parse(localStorage.getItem('userInfo')).userInfo.userCode;
            LoginOut({ username: userCode }).then(res => {
                if (res.code) {
                    this.$message({
                        message: 'Exit successfully',
                        center: true,
                        type: 'success',
                    });
                    bus.$off('handleLogin');
                    this.$router.replace({ path: '/' });
                    localStorage.removeItem('token');

                    sessionStorage.clear();
                } else {
                    this.$router.replace({ path: '/' });
                    localStorage.removeItem('token');
                    sessionStorage.clear();
                }
            });
        },
    },

    beforeDestroy() {
        clearInterval(this.timeserve);
        this.timeserve = null;
        window.removeEventListener('resize', this.handleResize);
    },
};
</script>
<style scoped>
.parnterr {
    color: red !important;
}
.parnterr1 {
    color: rgb(85, 180, 25) !important;
}
.product .search_input /deep/ .el-input__inner {
    border: none !important;
    padding-left: 0 !important;
}
.guidng {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 200;
}
.search_left .head_pro_cas /deep/ .el-input__inner {
    background-color: transparent;
    /* height: 42px; */
    /* opacity: 0; */
    font-size: 14px !important;
}
.search_left .head_pro_cas /deep/ .el-input__inner ::input-placeholder {
    color: black;
}
/deep/ .el-input__suffix {
    right: -23px !important;
}
.head_pro_cas /deep/ .el-input__suffix {
    right: 4px !important;
}
.head_box {
    width: 100%;
    height: 90px;
    background: #1290c9;
}
.search2 {
    border: 1px solid red !important;
    transition: border-color 0.3s ease-in-out;
    /* color: #022955; */
}
.shake {
    animation: shake 0.5s;
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-5px);
    }
    50% {
        transform: translateX(5px);
    }
    75% {
        transform: translateX(-5px);
    }
    100% {
        transform: translateX(0);
    }
}
.head_cont {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}
.head_logo {
    display: inline-block;
    overflow: hidden;
}
.head_logo img {
    width: 194px;
}
/* 搜索 */
.head_search {
    width: 950px;
    height: 54px;
    background: #ffffff;
    border-radius: 14px;
    border: 1px solid #dddddd;
    color: #022955;
    margin-left: 62px;
}
.search_right {
    flex: 1;
    position: relative;
}
.search_input {
    width: 100%;
    word-wrap: break-word;
    line-height: 30px;
    outline: none;
    border: none;
    padding: 0 20px;
    font-size: 14px;
}
.search_btn {
    width: 65px;
    height: 54px;
    cursor: pointer;
}
.search_btn img {
    width: 24px;
}
/* 选择下拉 */
.search_left {
    width: 170px;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
}
.search_left::after {
    content: '';
    position: absolute;
    right: 0;
    top: 17px;
    width: 1px;
    height: 20px;
    background-color: #e7eaed;
}
.search_left /deep/ .el-input__inner {
    border: none;
    font-size: 16px;
}
/deep/ .el-input__inner {
    padding-right: 0px !important;
}
.country_flag {
    display: flex;
    align-items: center;
}
.country_flag_img {
    width: 36px;
    height: 22px;
    margin: 15px 2px 15px 15px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
}
.sel_value {
    width: 64px;
    font-size: 14px;
    word-break: break-all;
}
.search_left .el-select {
    position: absolute;
    top: 0;
    width: 144px;
}
.search_left /deep/ .el-select .el-input__inner {
    background-color: transparent;
    height: 52px;
    /* opacity: 0; */
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.search_left.on /deep/ .el-select .el-input__inner {
    padding-left: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.head_pro_cas /deep/ .el-input__inner::placeholder,
.search_left /deep/ .el-select .el-input__inner::placeholder {
    color: #022955;
}
.head_pro_cas /deep/ .el-icon-arrow-down:before,
.search_left /deep/ .el-select .el-icon-arrow-up:before {
    color: #8497ab !important;
    font-weight: 600;
}
.sel_place {
    width: 100%;
    line-height: 52px;
    padding-left: 20px;
    display: inline-block;
    font-size: 14px;
}
.el-select-dropdown__wrap {
    max-height: 375px !important;
}
/* .el-cascader__dropdown{
z-index: 9999999 !important;
} */
.el-select-dropdown__list {
    padding: 10px 0 !important;
}
.search_sel_container {
    width: 1200px;
}
.search_sel_container /deep/ .el-tabs--left .el-tabs__nav-wrap.is-left {
    width: 240px;
}
.search_sel_container /deep/ .el-tabs--left .el-tabs__nav-wrap.is-left::after {
    width: 1px;
    background-color: #eee;
}
/deep/ .el-popper {
    z-index: 999999 !important;
}
.el-cascader__dropdown {
    z-index: 999999 !important;
}
.search_sel_container /deep/ .el-tabs__active-bar {
    display: none;
}
.search_sel_container .right {
    width: 959px;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    max-height: 340px;
    align-content: flex-start;
    margin-right: 1px;
    padding: 0 12px;
    overflow: auto;
}
.search_sel_container /deep/ .el-tabs--left .el-tabs__item.is-left {
    height: 42px;
    line-height: 42px;
    margin-bottom: 8px;
        padding: 0 10px !important;
}
.search_sel_container /deep/ .el-tabs__item.is-left.is-active {
    background: rgba(18, 144, 201, 0.1);
}

.el-select-dropdown__item:hover {
    background: rgba(18, 144, 201, 0.1);
}

.search_sel_container /deep/ .el-tabs__item:hover {
    color: #1290c9;
    font-weight: normal;
}
.search_sel_container .left_txt {
    font-size: 16px;
}
.search_sel_container .right_txt {
    font-size: 12px;
    color: #8497ab;
}
.search_sel_container .right .el-select-dropdown__item {
    width: 222px;
    height: 56px;
    padding: 0 10px;
    margin: 10px 5px;
    border: 1px solid #fff;
}
.search_sel_container .right /deep/ .el-select-dropdown__item.selected,
.search_sel_container .right /deep/ .el-select-dropdown__item.hover {
    background: rgba(18, 144, 201, 0.1);
    border-radius: 14px;
    border: 1px solid #1290c9;
}
.search_sel_container .right /deep/ .el-select-dropdown__item.selected {
    color: #022955;
    font-weight: normal;
}
.right_item {
    height: 100%;
    width: 100%;
}
.search_sel_container .right .img {
    width: 54px;
    height: 100%;
    margin-right: 5px;
}
.search_sel_container .right .img img {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
}
.search_sel_container .right .rBox {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.search_sel_container .right .rBox .coun_title {
    font-size: 16px;
    line-height: 1;
    margin-bottom: 4px;
    max-width: 140px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.search_sel_container .right .rBox .coun_num {
    font-size: 12px;
    line-height: 1;
    color: #8497ab;
}
.head_notice,
.head_notice2 {
    width: 24px;
    margin-left: 82px;
    cursor: pointer;
}
.head_notice3 {
    width: 24px;
    margin-left: 0px;
    cursor: pointer;
}
.head_notice /deep/ .el-badge__content,
.head_notice2 /deep/ .el-badge__content {
    border: none;
}
.head_notice /deep/ .el-badge__content.is-dot,
.head_notice2 /deep/ .el-badge__content.is-dot {
    width: 10px;
    height: 10px;
}
.head_notice /deep/ .el-badge__content--warning {
    background: rgba(255, 192, 0);
}
.head_notice2 /deep/ .el-badge__content--warning {
    background: rgba(255, 192, 0);
    display: none;
}
.head_notice /deep/ .el-badge__content.is-fixed.is-dot {
    top: 4px;
    right: 10px;
}

.head_login {
    position: relative;
    margin-left: 30px;
    width: 98px;
    height: 34px;
    /* background: #006a9a; */
    background: #8ec9e4;
    /* box-shadow: inset 0px 1px 2px 0px #054562; */
    border-radius: 17px;

    /* background-image: url('../assets/img/anjiubeijing.png'); */
}
.head_login::after {
    content: '';
    position: absolute;
    left: 3px;
    top: 3px;
    width: 28px;
    height: 28px;
    background: #ffffff;
    border-radius: 50%;
}
.head_swi_keyword {
    position: relative;
}
.head_swi_keyword .el-switch,
.head_login .el-switch {
    height: 34px;
    line-height: 34px;
}
.head_swi_keyword /deep/ .el-switch__core,
.head_login /deep/ .el-switch__core {
    width: 98px !important;
    height: 34px;
    background: #006a9a;
    box-shadow: inset 0px 1px 2px 0px #054562;
    border-radius: 17px;
    border: none;
}
.head_swi_keyword /deep/ .el-switch__core {
    width: 100px !important;
}
.head_swi_keyword /deep/ .el-switch.is-checked .el-switch__core {
    background: #ff7600;
    box-shadow: inset 0px 1px 2px 0px #aa4f00;
}
.head_swi_keyword /deep/ .el-switch__core:after,
.head_login /deep/ .el-switch__core:after {
    top: 3px;
    left: 3px;
    width: 28px;
    height: 28px;
    background: #ffffff;
}

.head_swi_keyword /deep/ .el-switch.is-checked .el-switch__core::after {
    left: 86px;
}
.head_login.person_role::after,
.head_login.company_role::after {
    left: 67px;
    /* background-color: #ff7600; */
    background-color: rgb(0, 49, 7);
    background-repeat: no-repeat;
    background-size: 14px 14px;
    background-position: center;
}
.head_login.company_role::after {
    /* background-image: url(../assets/img/renyuan-shi.png); */
    background-image: url(../assets/img/head_switch_company_ic.png);
}
.head_login.person_role::after {
    background-image: url(../assets/img/head_switch_person_ic.png);
}
.head_login.company_role .switch_txt,
.head_login.person_role .switch_txt,
.head_swi_keyword /deep/ .el-switch.is-checked + .switch_txt {
    left: 12px;
}
.switch_txt {
    position: absolute;
    left: 35px;
    top: 10px;
    font-size: 14px;
    color: #ffffff;
    line-height: 16px;
}
/* 菜单 */
.head_menu_box {
    width: 100%;
    /* height: 48px; */
    height: 40px;
    background: #fff;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
}
.head_menu_list {
    display: flex;
    justify-content: center;
}
.head_menu_list /deep/ .el-radio-button__inner {
    /* padding: 16px 0px 12px; */
    padding: 11px 0px 12px;
}
.head_menu_list /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #fff;
    background-color: #fff;
    border-color: #1290c9;
    box-shadow: none;
    /* top: 8px; */
}
.head_menu_item /deep/ .el-radio-button__inner {
    border: none;
    /* border-bottom: 2px solid transparent; */
    border-radius: 0;
    /* line-height: 18px; */
    line-height: 17px;
    font-size: 14px;
    color: #022955;
}
.head_menu_item {
    line-height: 20px;
    font-size: 14px;
    /* padding: 18px 30px 10px 30px; */
    text-align: center;
    /* margin: 0 30px; */
    padding: 0 30px;
}

.is-active /deep/ .el-radio-button__inner {
    background-image: url('../assets/img/tabactive4.png');
    height: 40px;
    width: 150px;
    background-position: center center;
    background-repeat: no-repeat;
}

.head_menu_list /deep/ .el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
    box-shadow: none;
}

.top_usage {
    padding: 15px 10px;
    background: #f2f5f9;
    border-radius: 14px;
    margin-bottom: 7px;
}
.usage_prog {
    flex: 1;
}
.usage_ic {
    width: 26px;
    height: 26px;
    line-height: 26px;
    /* background: #ffffff; */
    background: rgb(0, 49, 7);
    border-radius: 50%;
    margin-right: 10px;
    text-align: center;
}
.usage_icone {
    background: #ffffff !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.usage_ic img {
    width: 12px;
    height: 12px;
}
.usage_icone img {
    width: 16px;
    height: 16px;
}
.usage_txt {
    font-size: 14px;
    color: #022955;
    margin-bottom: 8px;
}
.usage_total {
    font-size: 12px;
    color: #8497ab;
}
.usage_used {
    margin-top: 8px;
    font-size: 12px;
    color: #1290c9;
}
.acc_signout,
.top_acc_menu_box li a {
    display: block;
    padding: 8px 10px;
    font-size: 14px;
    color: #022955;
    cursor: pointer;

    display: flex;
    align-items: center;
}
.acc_signout:hover,
.top_acc_menu_box li a:hover,
.top_acc_menu a.router-link-exact-active.router-link-active {
    color: #1290c9;
}
.acc_signout:hover,
.top_acc_menu_box li a:hover {
    background: #ebf2ff;
    border-radius: 14px;
}
.head_login,
.acc_signout {
    cursor: pointer;
}
</style>
<style>
.el-popover.pop_top_account {
    padding: 10px !important;
    z-index: 999999 !important;
}
.head_pro_cas {
    /* margin-top: 5px; */
    color: black;
}
.el-cascader-node.in-active-path,
.el-cascader-node.is-active,
.el-cascader-node.is-selectable.in-checked-path {
    font-weight: normal;
    color: #1290c9 !important;
    background: rgba(18, 144, 201, 0.1);
}
.head_cas .el-cascader-menu {
    max-width: 240px;
    z-index: 999999 !important;
}
.head_cas {
    z-index: 999999 !important;
}
.head_cas .el-cascader-menu__wrap {
    height: 350px;
}
.el-cascader-node__label {
    font-size: 16px !important;
}
.head_cas .el-cascader-node {
    color: #022955;
    padding: 0 59px 0 20px !important;
}
.top_usage-head {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.userinfo-li {
    list-style: none;
    display: flex;
    align-items: center;
    /* padding: 0px 10px; */
}
.el-select-dropdown__item {
    color: #022955;
}
.totip {
    z-index: 9999999 !important;
}
.websorct {
    position: absolute;
    right: 80px;
    top: 70px;
    width: 450px;
    height: 300px;
    border: 1px solid #ebeef5;
    z-index: 999999 !important;
    text-align: justify;
    font-size: 14px;
    background-color: white;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow-y: auto;
}
.websorctli {
    list-style: none;
    margin: 8px 10px;
    font-size: 14px;
    color: #022955;
    cursor: pointer;
    width: 430px;
    background: #f2f5f9;
    border-radius: 14px;
}
.websorctlitext {
    padding: 0px 10px;
    line-height: 24px;
    /* word-wrap: break-word; */
    /* overflow-wrap: break-word;  */
}
.msesage {
    position: relative;
    top: -20px;
    left: 2px;
    font-size: 12px;
    color: #fff;
}
.websorct-titel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 15px 0px;
    height: 50px;
}
.websorct-titel .btn_export {
    min-width: 108px;
    height: 32px;
    line-height: 30px;
    padding: 0 23px;
    font-size: 14px;
    color: #fff;
    text-decoration: none;
    letter-spacing: 1px;
    border-radius: 20px;
}
.phone_head_box {
    display: none;
}
@media (max-width: 821px) {
    .search_box {
        width: 98% !important;
    }
    .home_container {
        height: 100% !important;
    }
    /* .title{
        font-size: 23px !important;
} */
    .subtitle {
        font-size: 16px !important;
    }

    .search_btn {
        font-size: 12px !important;
        width: 15% !important;
    }
    .search_btn img {
        width: 12px !important;
        height: 12px !important;
    }
    .search_left {
        width: 30% !important;
    }
    .country_flag_img {
        width: 22px !important;
        height: 12px !important;
        margin: 22px 6px 24px 10px !important;
    }
    .search_sel_container {
        width: 100% !important;
    }
    .search_left.on .el-select .el-input__inner {
        padding-left: 38px !important;
    }
    .search_left .el-input__inner {
        border: none !important;
        font-size: 12px !important;
    }
    .search_sel_container .el-tabs__nav-wrap.is-left {
        width: 170px !important;
    }
    .search_sel_container .right {
        width: 100% !important;
    }
    .right .rBox .coun_title {
        font-size: 12px !important;
        max-width: 95px !important;
    }
    .search_sel_container .right .img {
        width: 30px !important;
    }
    .phoneseach_left {
        display: none !important;
    }
    .el-autocomplete-suggestion {
        width: 336px !important;
    }
    .search_option {
        /* display: none; */
    }
    .search_sel_container .right .el-select-dropdown__item {
        height: 36px !important;
    }
    .search_input {
        font-size: 14px !important;
    }

    .head_search {
        width: 91% !important;
        margin-left: 2px !important;
        position: relative;
        left: 30px;
    }
    .head_notice {
        display: none !important;
    }
    .head_menu_box {
        /* display: none; */
    }
    .head_swi_keyword {
        display: none;
    }
    .search_left .el-select {
        width: 70% !important;
    }
    .head_box {
        height: 160px !important;
        padding: 15px 20px !important;
    }
    .phone_head_box {
        display: block !important;
    }
    .computer_head_box {
        display: none !important;
    }
    .head_notice2 {
        display: none !important;
    }
    .head_cont {
        flex-wrap: wrap;
    }
    .head_logo {
        width: 40%;
    }
    .head_logo img {
        width: 90px !important;
        position: relative;
        left: -10px;
    }
    .phone_max_cont_width {
        overflow: auto;
    }
    .product .search_input .el-input__inner {
        font-size: 15px !important;
    }
    .popperClass {
        margin-top: 100px !important;
    }
    .head_menu_list {
        width: 100% !important;
        flex-wrap: wrap;
    }
    .head_menu_item {
        width: 100%;
        text-align: left !important;
        line-height: 50px !important;
    }
    .phone_max_cont_width::-webkit-scrollbar {
        /* background-color: #E9ECF0; */
        background-color: transparent;
        width: 0px;
        height: 0px;
        background-clip: padding-box;
    }
    .sel_box {
        top: 140px !important; /* 设置下拉框距离`el-select`的顶部的距离 */
        left: 0 !important;
    }

    .head_login.company_role::after {
        background-color: rgb(0, 49, 7) !important;
    }
    .head_login::after {
        background-color: #1290c9 !important;
    }
    .bhpne_menu {
        position: absolute;
        top: 80px;
        left: -5px;
        height: 30px;
        width: 28px;
    }
    .head_menu_list .is-active .el-radio-button__inner {
        background-image: none !important;
        height: 40px;
        /* width: 150px; */
        background-position: center center;
        background-repeat: no-repeat;
    }
    .head_menu_list .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        color: #1290c9 !important;

        text-align: left;
        background-image: none !important;
        /* top: 8px; */
    }
    .head_cont .head_login {
        background: none !important;
    }
}
</style>
